import { template as template_e83c5f0c9a574a4e9d276c59152d3ea7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e83c5f0c9a574a4e9d276c59152d3ea7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
