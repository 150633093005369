import { template as template_2c61833b51eb4795a22447cd07c68c7d } from "@ember/template-compiler";
const FKText = template_2c61833b51eb4795a22447cd07c68c7d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
