import { template as template_86477b9243b447d6a55ea4f8a9436a76 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_86477b9243b447d6a55ea4f8a9436a76(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
